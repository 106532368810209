import { Flex, Tag } from "@grupoboticario/flora-react";
import { ChevronRightIcon } from "@grupoboticario/flora-react-icons";
import { Table } from "@grupoboticario/flora-team-react-table";
import { TableRoot, TableHead, TableCell } from "./TeamList.style";
import { useTeamList } from "./useTeamList";
import { Link, useNavigate } from "react-router-dom";

export function TeamList() {
  const { data: teams, isError, isLoading } = useTeamList();
  const navigate = useNavigate();

  function handleTeamClick(teamId: string) {
    navigate(`/equipes/${teamId}`);
  }

  if (isLoading) {
    return <div>Carregando lista de equipes...</div>;
  }

  if (isError) {
    return <div>Erro ao carregar a lista de equipes</div>;
  }

  return (
    <Flex css={{ flexDirection: "column", alignItems: "center", gap: "$6" }}>
      <TableRoot>
        <thead>
          <Table.Row>
            <TableHead align="left">Nome da equipe</TableHead>
            <TableHead align="left">Responsável</TableHead>
            <TableHead align="left">Integrantes</TableHead>
            <TableHead></TableHead>
          </Table.Row>
        </thead>
        <tbody>
          {teams?.map((team) => (
            <Table.Row
              data-testid={`${team.id}`}
              key={team.id}
              onClick={() => handleTeamClick(team.id)}
              css={{ cursor: "pointer" }}
            >
              <TableCell
                align="left"
                css={{ width: "60%", "@desktop": { width: "70%" } }}
              >
                {team.name}
              </TableCell>
              <TableCell align="left">
                {team?.responsibles?.[0] ? (
                  <Tag
                    variant="primary"
                    css={{
                      backgroundColor: "$backgroundAltPrimary",
                      fontSize: "$bodySmall",
                      fontWeight: "$medium",
                    }}
                    size="medium"
                  >
                    {team.responsibles[0].name}
                  </Tag>
                ) : null}
                {team?.responsibles?.length >= 2 ? (
                  <Tag
                    variant="primary"
                    size="medium"
                    css={{
                      backgroundColor: "$backgroundAltPrimary",
                      fontSize: "$bodySmall",
                      fontWeight: "$medium",
                    }}
                    title={
                      team.responsibles
                        .slice(1)
                        .map((responsible) => responsible.email)
                        .join("\n\n") ?? ""
                    }
                  >
                    +{team.responsibles.length - 1}
                  </Tag>
                ) : null}
              </TableCell>
              <TableCell>{team.totalMembers}</TableCell>
              <TableCell align="right" asChild>
                <Table.CellButton as={Link} to={`/equipes/${team.id}`}>
                  <ChevronRightIcon color="$actionableDefault" />
                </Table.CellButton>
              </TableCell>
            </Table.Row>
          ))}
        </tbody>
      </TableRoot>
    </Flex>
  );
}
